import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";
import BodyText from "lib/@cms/components/shared/BodyText";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Divider from "lib/@cms/components/primitive/Divider";

function OurProcess() {
  return (
    <Page pathname="our-process">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />
            <div className="tw-container">
              <Text variant={Text.variant.BODY}>{data.MetaData.description}</Text>

              <ListSection texts={data.TextsLists} />
              <ContentBox title={data.TextBodyList.title}>
                <div className="tw-list-disc">
                  {data.TextBodyList.list?.map((element, index) => {
                    return <span key={`TextBodyList-${index}`}>{element.item}</span>;
                  })}
                </div>
              </ContentBox>
              <Divider className="tw-my-16 sm:tw-my-24" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default OurProcess;

// --- Components ---

function ListSection({ texts }) {
  return texts?.map((item, index) => {
    return (
      <React.Fragment key={`ListSection-item-${index}`}>
        <BodyText
          data={item}
          variant={
            item.list?.length <= 1
              ? BodyText.variant.DECORATION_NONE
              : BodyText.variant.DECORATION_DOTTEN
          }
        />
        <Divider className="tw-my-7" />
      </React.Fragment>
    );
  });
}
